import { Fragment, useState } from 'react';
import {
  EXCLUDED_RESOURCES_TABLE_COLUMNS,
  GROUP_RESOURCE_TYPE,
  SORT_ASCENDING,
  SORT_DESCENDING,
} from '../../../../constants/constants';
import arrow from '../../../../assets/icons/arorw_right.svg';
import OutlineIconButton from '../../../common/OutlineIconButton';
import clearSortIcon from '../../../../assets/icons/clear_sort.svg';
import CommentTooltip from '../CommentTooltip';

const sortExcludedResources = (a, b, sortConfigurations) => {
  for (const config of sortConfigurations) {
    let aValue = a[config.column];
    let bValue = b[config.column];

    if (config.column === 'type') {
      if (!aValue) {
        aValue = GROUP_RESOURCE_TYPE;
      }
      if (!bValue) {
        bValue = GROUP_RESOURCE_TYPE;
      }
    }
    if (config.column === 'id') {
      if (aValue < bValue) return config.direction === SORT_ASCENDING ? -1 : 1;
      if (aValue > bValue) return config.direction === SORT_ASCENDING ? 1 : -1;
    }
    if (aValue.toString().localeCompare(bValue) === -1)
      return config.direction === SORT_ASCENDING ? -1 : 1;
    if (aValue.toString().localeCompare(bValue) === 1)
      return config.direction === SORT_ASCENDING ? 1 : -1;
  }
  return 0;
};

export const ExcludedResourceRows = ({
  excludedResources,
  setRemoveFromListResource,
}) => {
  const [sortConfigurations, setSortConfigurations] = useState([
    {
      column: 'name',
      direction: SORT_ASCENDING,
    },
  ]);
  const [expandedGroupId, setExpandedGroupId] = useState(null);

  const toggleGroup = (groupId) => {
    setExpandedGroupId((prevExpandedGroupId) =>
      prevExpandedGroupId === groupId ? null : groupId
    );
  };
  const handleSort = (column) => {
    let newSortConfigurations = [...sortConfigurations];
    const existingConfig = newSortConfigurations.find(
      (config) => config.column?.toLowerCase() === column.toLowerCase()
    );

    if (existingConfig) {
      newSortConfigurations = newSortConfigurations.filter(
        (config) => config.column.toLowerCase() !== column.toLowerCase()
      );
      if (existingConfig.direction === SORT_ASCENDING) {
        newSortConfigurations.push({
          column: column.toLowerCase(),
          direction: SORT_DESCENDING,
        });
      }
    } else {
      newSortConfigurations.push({
        column: column.toLowerCase(),
        direction: SORT_ASCENDING,
      });
    }

    setSortConfigurations(newSortConfigurations);
  };

  const SortIndicator = ({ column }) => {
    const existingConfigIndex = sortConfigurations.findIndex(
      (config) => config.column?.toLowerCase() === column.toLowerCase()
    );

    return (
      <>
        <div
          className={`ml-1.5${
            sortConfigurations.length < 2 || existingConfigIndex === -1
              ? ' invisible'
              : ''
          }`}
        >
          {existingConfigIndex + 1}
        </div>
        <div
          className={`material-symbols-outlined text-lg leading-none${
            existingConfigIndex === -1 ? ' invisible' : ''
          }`}
        >
          {sortConfigurations[existingConfigIndex]?.direction === SORT_ASCENDING
            ? 'arrow_upward'
            : 'arrow_downward'}
        </div>
      </>
    );
  };

  return (
    <div className="w-full mt-4">
      <div
        className="table border-collapse w-full text-sm"
        style={{ tableLayout: 'fixed' }}
      >
        <div className="table-header-group h-[40px]">
          <div className="table-row border-b-2 text-15 font-medium text-purple-vs-hover">
            {EXCLUDED_RESOURCES_TABLE_COLUMNS.map((column, index) => (
              <div
                key={column}
                className={`table-cell p-2 ${column !== 'Comment' ? 'cursor-pointer' : ''}`}
                style={{
                  width: index === 2 || index === 3 ? '35%' : '10%',
                }}
                onClick={() => {
                  if (column !== 'Comment') handleSort(column);
                }}
              >
                <div className="inline-flex">
                  <div>{column}</div>
                  {column !== 'Comment' && <SortIndicator column={column} />}
                </div>
              </div>
            ))}
            <div className="table-cell text-right" style={{ width: '20%' }}>
              {sortConfigurations.length > 0 && (
                <OutlineIconButton
                  text="Clear Sort"
                  outlineColor="border-gray-vs"
                  bgClass="bg-white"
                  padding="px-2 py-1"
                  icon={clearSortIcon}
                  widthClass="w-[110px]"
                  onClick={() => setSortConfigurations([])}
                />
              )}
            </div>
          </div>
        </div>
        <div className="table-row-group text-[13px] font-light">
          {excludedResources
            .sort((a, b) => sortExcludedResources(a, b, sortConfigurations))
            .map((resource) => {
              const isExpanded = expandedGroupId === resource.id;
              return (
                <Fragment key={resource.id + resource.name}>
                  <div
                    role="row"
                    className={`table-row border-b-2 h-12 ${
                      resource.descendants?.length > 0 && 'cursor-pointer'
                    }`}
                    onClick={() => toggleGroup(resource.id)}
                  >
                    <div className="config-table-cell" style={{ width: '10%' }}>
                      {resource.id}
                    </div>
                    <div
                      className="config-table-cell"
                      style={{
                        width: '10%',
                        ...(resource.type
                          ? {
                              color: '#4994EC',
                            }
                          : {}),
                      }}
                    >
                      {resource.type || GROUP_RESOURCE_TYPE}
                    </div>
                    <div className="config-table-cell" style={{ width: '35%' }}>
                      <div className="flex items-center">
                        <span className="truncate">{resource.name}</span>
                        {resource.descendants?.length > 0 && (
                          <span className="ml-2 flex-shrink-0">
                            ({resource.descendants?.length})
                          </span>
                        )}
                        {resource.descendants?.length > 0 && (
                          <img
                            src={arrow}
                            width="16"
                            height="16"
                            alt=""
                            className="ml-2 flex-shrink-0"
                            style={{
                              transform: isExpanded
                                ? 'rotate(-90deg)'
                                : 'rotate(90deg)',
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="config-table-cell" style={{ width: '35%' }}>
                      <CommentTooltip comment={resource.comment || ''} />
                    </div>
                    <div
                      className="config-table-cell text-right"
                      style={{ width: '10%' }}
                    >
                      <div
                        className="inline-block ml-5 text-warning-red cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          setRemoveFromListResource(resource);
                        }}
                      >
                        Remove From List
                      </div>
                    </div>
                  </div>
                  {isExpanded &&
                    resource.descendants?.map((childGroup) => (
                      <div
                        role="row"
                        key={childGroup.id}
                        className="table-row border-b-2 h-12"
                      >
                        <div
                          className="config-table-cell"
                          style={{ paddingLeft: '2rem', width: '20%' }}
                        >
                          {childGroup.id}
                        </div>
                        <div
                          className="config-table-cell"
                          style={{ paddingLeft: '2rem', width: '60%' }}
                        >
                          <span className="truncate">{childGroup.name}</span>
                        </div>
                        <div
                          className="config-table-cell"
                          style={{ width: '20%' }}
                        ></div>
                      </div>
                    ))}
                </Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};
