import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ColoredButton from '../../common/ColoredButton';
import { loadPractices } from '../../../features/table/tableActions';
import FormModal from '../../common/FormModal';
import ExcludeResourceForm from './forms/ExcludeResourceForm';
import ConfirmationModal from '../../common/ConfirmationModal';
import { ExcludedResourceRows } from './helpers/ExcludedResourceRows';
import Loader from '../../common/Loader';
import { getExcludedResourceConfirmationText } from '../../../helpers/modalHelper';
import {
  deleteExcludedResource,
  getExcludedResources,
} from '../../../services/excludedResourcesServices';
import { getValueById } from '../../../helpers/tableHelper';
import { loadFullGroups } from '../../../features/groups/groupsActions';
import { getGroupSelectElements } from '../../../helpers/selectHelper';
import {
  isGroupResourceType,
  refreshPage,
} from '../../../helpers/commonHelper';
import { ErrorContext } from '../../../ErrorContext';

export default function ExcludedResources() {
  const { errorAlert, setErrorAlert } = useContext(ErrorContext);
  const { practices, preLoadingError } = useSelector(
    (state) => state.tableData
  );

  const { groupsDetails, loadingGroups, loadingGroupsError } = useSelector(
    (state) => state.groups
  );
  const dispatch = useDispatch();
  const [loadingExcludedResources, setLoadingExcludedResources] =
    useState(true);
  const [excludedResources, setExcludedResources] = useState(null);
  const [availableGroupsForSelect, setAvailableGroupsForSelect] = useState([]);
  const [availablePracticesForSelect, setAvailablePracticesForSelect] =
    useState(practices);
  const [excludeNewPracticeGroupOpen, setExcludeNewPracticeGroupOpen] =
    useState(false);
  const [removeFromListResource, setRemoveFromListResource] = useState(null);

  const mapExcludedResource = ({ resource_id, id, resource_type, comment }) => {
    const resourceGroup = isGroupResourceType(resource_type)
      ? getGroupById(resource_id)
      : null;
    return {
      deleteId: id,
      id: resource_id,
      type: resource_type,
      name: resourceGroup?.name || getValueById(practices, resource_id),
      descendants:
        resourceGroup?.descendants_ids.map((id) => getGroupById(id)) || [],
        comment: comment,
    };
  };

  const getGroupById = (id) => {
    return groupsDetails.find((e) => e.id === id);
  };

  useEffect(() => {
    if (preLoadingError || loadingGroupsError) {
      setErrorAlert({
        error: preLoadingError || loadingGroupsError,
        buttonText: 'Try reloading',
        onButtonClick: refreshPage,
        closingButton: true,
      });
    }
  }, [preLoadingError, loadingGroupsError]);

  useEffect(() => {
    if (!groupsDetails) {
      dispatch(loadFullGroups());
    }
  }, [groupsDetails]);

  useEffect(() => {
    if (!practices) {
      dispatch(loadPractices());
    }
  }, [practices]);

  useEffect(() => {
    if (groupsDetails && excludedResources) {
      let excludedResourceIds = excludedResources.reduce(
        (excludedResources, resource) => {
          if (isGroupResourceType(resource.type)) {
            excludedResources.excludedGroups.push(resource.id);
            if (resource.descendants.length > 0) {
              excludedResources.excludedGroups.push(
                ...resource.descendants.map((r) => r.id)
              );
            }
          } else {
            excludedResources.excludedPractices.push(resource.id);
          }
          return excludedResources;
        },
        { excludedGroups: [], excludedPractices: [] }
      );

      setAvailableGroupsForSelect(
        getGroupSelectElements(
          groupsDetails.filter((group) => {
            return !excludedResourceIds.excludedGroups.includes(group.id);
          })
        )
      );
      setAvailablePracticesForSelect(
        practices.filter((practice) => {
          return !excludedResourceIds.excludedPractices.includes(practice.id);
        })
      );
    }
  }, [excludedResources, groupsDetails]);

  const loadExcludedResources = () => {
    setLoadingExcludedResources(true);
    getExcludedResources()
      .then((excludedResources) => {
        setExcludedResources(excludedResources.data.map(mapExcludedResource));
      })
      .catch((error) => {
        setErrorAlert({
          error: error,
          buttonText: 'Try reloading',
          onButtonClick: refreshPage,
          closingButton: true,
        });
      })
      .finally(() => {
        setLoadingExcludedResources(false);
      });
  };

  useEffect(() => {
    if (practices && groupsDetails) {
      loadExcludedResources();
    }
  }, [practices, groupsDetails]);

  const onRemoveFromList = () => {
    deleteExcludedResource(removeFromListResource.deleteId)
      .then(() => {
        loadExcludedResources();
      })
      .catch((error) => {
        setErrorAlert({
          error: error,
          onButtonClick: () => loadExcludedResources(),
          onClose: () => loadExcludedResources(),
        });
      })
      .finally(() => setRemoveFromListResource(null));
  };

  const onAddNewResource = () => {
    loadExcludedResources();
    setExcludeNewPracticeGroupOpen(false);
  };

  return (
    <>
      {(!practices || loadingExcludedResources || loadingGroups) &&
        !loadingGroupsError &&
        !errorAlert &&
        !preLoadingError && <Loader />}
      {!preLoadingError &&
        !loadingExcludedResources &&
        practices &&
        groupsDetails &&
        excludedResources && (
          <div>
            <div className="flex justify-end">
              <ColoredButton
                padding="px-1"
                text="Add New"
                widthClass="w-[100px]"
                onClick={() => setExcludeNewPracticeGroupOpen(true)}
              />
            </div>
            <ExcludedResourceRows
              excludedResources={excludedResources}
              setRemoveFromListResource={setRemoveFromListResource}
            />
          </div>
        )}
      <FormModal
        openState={excludeNewPracticeGroupOpen}
        onClose={() => setExcludeNewPracticeGroupOpen(false)}
        title="Exclude Practice or Group"
        form={
          <ExcludeResourceForm
            groups={availableGroupsForSelect}
            practices={availablePracticesForSelect}
            onClose={() => setExcludeNewPracticeGroupOpen(false)}
            onFinish={onAddNewResource}
          />
        }
      />
      {removeFromListResource && (
        <ConfirmationModal
          title="Are you sure?"
          text={getExcludedResourceConfirmationText(
            removeFromListResource.type,
            removeFromListResource.id,
            removeFromListResource.name,
            removeFromListResource.descendants?.length || 0,
            'allow'
          )}
          buttonText="Yes, I’m sure"
          overrideDefaultPosition="mt-4"
          onButtonClick={onRemoveFromList}
          onClose={() => setRemoveFromListResource(null)}
          isOpen={!!removeFromListResource}
        />
      )}
    </>
  );
}
