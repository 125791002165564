import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosSecureInstance } from './apiSecure.js';

const axiosBaseQuery =
  () =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axiosSecureInstance({
        url,
        method,
        data,
        params,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError.response
        ? axiosError.response.data
        : { status: 'FETCH_ERROR', error: axiosError.message };

      return { error: err };
    }
  };

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getCategoryListRecords: build.query({
      queryFn: async (ids, _api, _extraOptions, baseQuery) => {
        try {
          const results = await Promise.all(
            ids.map(async (id) => {
              const response = await baseQuery({
                url: `/category_list_records/${id}`,
                method: 'get',
              });
              return { id, data: response.data };
            })
          );

          const data = results.reduce((acc, { id, data }) => {
            acc[id] = data;
            return acc;
          }, {});

          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    getMappingConfigurations: build.query({
      query: () => ({ url: '/mapping_configurations', method: 'get' }),
      transformResponse: (response) => {
        const colors = [
          'bg-red-500',
          'bg-green-500',
          'bg-blue-500',
          'bg-yellow-500',
        ];
        return response.map((item, index) => ({
          ...item,
          color: colors[index % colors.length],
        }));
      },
    }),
    getCategoryListRecord: build.query({
      query: (id) => ({ url: `/category_list_records/${id}`, method: 'get' }),
    }),
  }),
});

export const {
  useGetCategoryListRecordsQuery,
  useGetMappingConfigurationsQuery,
  useGetCategoryListRecord,
} = api;
