import { useContext, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  CellSelectionModule,
  ColumnMenuModule,
  DateFilterModule,
  EventApiModule,
  FiltersToolPanelModule,
  NumberFilterModule,
  PaginationModule,
  RowApiModule,
  ServerSideRowModelApiModule,
  ServerSideRowModelModule,
  SetFilterModule,
  SideBarModule,
  StatusBarModule,
  TextFilterModule,
} from 'ag-grid-enterprise';
import { useAgGridConfig } from './useAgGridConfig.js';
import {
  useGetCategoryListRecordsQuery,
  useGetMappingConfigurationsQuery,
} from '../../../services/apiSlice.js';
import { ErrorContext } from '../../../ErrorContext.jsx';
import { TopPanel } from './TopPanel';
import { ColoredSelect } from './ColoredSelect';
import Loader from '../../common/Loader.jsx';
import { refreshPage } from '../../../helpers/commonHelper.jsx';
import {
  createColumnDefs,
  getCategoryListIds,
} from './AdminGenericMappingUtils.js';

const agGridModules = [
  CellSelectionModule,
  ColumnMenuModule,
  DateFilterModule,
  EventApiModule,
  FiltersToolPanelModule,
  NumberFilterModule,
  PaginationModule,
  RowApiModule,
  ServerSideRowModelApiModule,
  ServerSideRowModelModule,
  SetFilterModule,
  SideBarModule,
  StatusBarModule,
  TextFilterModule,
];

export default function AdminGenericMapping() {
  const [mappingType, setMappingType] = useState(null);
  const categoryListIds = getCategoryListIds(mappingType);

  const {
    data: mappingTypes,
    error: errorMappingTypes,
    isLoading: isLoadingMappingTypes,
  } = useGetMappingConfigurationsQuery();
  const {
    data: categoryListRecords,
    error: errorCategoryListRecords,
    isLoading: isLoadingCategoryListRecords,
  } = useGetCategoryListRecordsQuery(categoryListIds, {
    skip: !mappingType,
  });

  const isLoading = isLoadingMappingTypes || isLoadingCategoryListRecords;
  const isError = errorMappingTypes || errorCategoryListRecords;
  const { errorAlert, setErrorAlert } = useContext(ErrorContext);

  const {
    columnTypes,
    defaultColDef,
    dataTypeDefinitions,
    getRowId,
    onGridReady,
    sideBar,
    paginationPageSizeSelector,
    statusBar,
    rowSelection,
    selectionColumnDef,
    cellSelection,
  } = useAgGridConfig(mappingType);

  const [columnDefs, setColumnDefs] = useState();

  useEffect(() => {
    if (mappingType && categoryListRecords) {
      const colDefs = createColumnDefs(mappingType, categoryListRecords);
      setColumnDefs(colDefs);
    }
  }, [mappingType, categoryListRecords]);

  useEffect(() => {
    if (isError) {
      setErrorAlert({
        error: isError,
        onButtonClick: refreshPage,
        buttonText: 'Try reloading',
        closingButton: true,
      });
    }
  }, [isError]);

  return (
    <>
      {isLoading && !errorAlert && <Loader />}
      {!isLoading && !errorAlert && (
        <div
          id="generic_mappings"
          className="flex flex-col h-full flex-grow-1 mx-4 mb-4"
        >
          <TopPanel
            rightSlot={
              <ColoredSelect
                options={mappingTypes}
                selectedOption={mappingType}
                setSelectedOption={setMappingType}
              />
            }
          />
          {columnDefs && (
            <div className="h-full w-full">
              <div className="h-full w-full">
                <AgGridReact
                  modules={agGridModules}
                  rowModelType="serverSide"
                  onGridReady={onGridReady}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  columnTypes={columnTypes}
                  dataTypeDefinitions={dataTypeDefinitions}
                  suppressSetFilterByDefault
                  getRowId={getRowId}
                  sideBar={sideBar}
                  pagination
                  paginationPageSize={50}
                  paginationPageSizeSelector={paginationPageSizeSelector}
                  statusBar={statusBar}
                  rowSelection={rowSelection}
                  selectionColumnDef={selectionColumnDef}
                  cellSelection={cellSelection}
                  suppressMultiSort
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
