import { useState, useRef } from 'react';
import { usePopper } from 'react-popper';

const CommentTooltip = ({ comment }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const referenceRef = useRef(null);
  const popperRef = useRef(null);
  const { styles, attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-250, 10],
          },
        },
      ],
    }
  );

  return (
    <>
      <div
        ref={referenceRef}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="truncate cursor-pointer"
      >
        {comment}
      </div>
      {showTooltip && (
        <div
          ref={popperRef}
          style={styles.popper}
          {...attributes.popper}
          className="absolute px-4 py-3 text-xs text-white rounded-[5px] z-10 bg-tooltip-black whitespace-normal max-w-xs"
        >
          {comment}
        </div>
      )}
    </>
  );
};

export default CommentTooltip;
