export const CATEGORY_LIST_TYPE_STRING = 'string';
export const CATEGORY_LIST_TYPE_FLOAT = 'float';
export const CATEGORY_LIST_TYPE_NUMBER = 'number';
export const CATEGORY_LIST_TYPE_QUERY = 'query';
export const CATEGORY_LIST_TYPE_LIST = 'list';

export const COMPLEX_CATEGORY_LIST_TYPES = [
  CATEGORY_LIST_TYPE_QUERY,
  CATEGORY_LIST_TYPE_LIST,
];
