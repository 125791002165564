import { useCallback, useContext, useMemo } from 'react';
import {
  getGenericMappings,
  getGenericMappingsCount,
} from '../../../services/genericMappingDataServices.js';
import { ErrorContext } from '../../../ErrorContext.jsx';
import {
  CATEGORY_LIST_TYPE_FLOAT,
  CATEGORY_LIST_TYPE_LIST,
  CATEGORY_LIST_TYPE_NUMBER,
  CATEGORY_LIST_TYPE_QUERY,
  CATEGORY_LIST_TYPE_STRING,
} from '../../../constants/genericMappingConstants.js';

export const useAgGridConfig = (mappingType) => {
  const { setErrorAlert } = useContext(ErrorContext);

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      floatingFilter: true,
    };
  }, []);

  const columnTypes = useMemo(() => {
    return {
      [CATEGORY_LIST_TYPE_FLOAT]: {
        filterParams: {
          filterOptions: ['equals'],
        },
      },
      [CATEGORY_LIST_TYPE_NUMBER]: {
        filterParams: {
          filterOptions: ['equals'],
        },
      },
      [CATEGORY_LIST_TYPE_STRING]: {
        filterParams: {
          filterOptions: ['contains', 'notContains'],
        },
      },
      [CATEGORY_LIST_TYPE_QUERY]: {
        filter: 'agSetColumnFilter',
        filterParams: {
          keyCreator: (params) => params.value.category_id,
          valueFormatter: (params) => params.value.value,
        },
      },
      [CATEGORY_LIST_TYPE_STRING]: {},
    };
  }, []);

  const dataTypeDefinitions = useMemo(() => {
    return {
      [CATEGORY_LIST_TYPE_FLOAT]: {
        extendsDataType: 'number',
        baseDataType: 'number',
      },
      [CATEGORY_LIST_TYPE_STRING]: {
        extendsDataType: 'text',
        baseDataType: 'text',
      },
      [CATEGORY_LIST_TYPE_QUERY]: {
        extendsDataType: 'object',
        baseDataType: 'object',
      },
      [CATEGORY_LIST_TYPE_LIST]: {
        extendsDataType: 'object',
        baseDataType: 'object',
      },
    };
  }, []);

  const getRowId = useCallback(
    (params) => {
      return String(params.data[mappingType?.unique_identifier]);
    },
    [mappingType]
  );

  const createServerSideDatasource = () => {
    return {
      getRows: async (params) => {
        getGenericMappings(mappingType.id)
          .then((rowData) => {
            params.success({ rowData });
          })
          .catch((error) => {
            setErrorAlert({ error: error });
            params.success({ rowData: [] });
          });

        if (params.request.startRow === 0) {
          getGenericMappingsCount(mappingType.id).then((rowCount) => {
            params.api.setRowCount(rowCount);
          });
        }
      },
    };
  };

  const onGridReady = useCallback(
    (params) => {
      const datasource = createServerSideDatasource();
      params.api.setGridOption('serverSideDatasource', datasource);
    },
    [mappingType]
  );

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressFilterSearch: true,
            suppressExpandAll: true,
            suppressSyncLayoutWithGrid: true,
          },
        },
      ],
      defaultToolPanel: 'filters',
    };
  }, []);

  const paginationPageSizeSelector = useMemo(() => {
    return [50, 100, 200];
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: 'agSelectedRowCountComponent',
        },
        {
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            aggFuncs: ['sum'],
          },
        },
      ],
    };
  }, []);

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
      headerCheckbox: false,
      checkboxes: false,
      enableClickSelection: true,
    };
  }, []);

  const selectionColumnDef = useMemo(() => {
    return {
      suppressMovable: false,
      lockPosition: false,
    };
  }, []);

  const cellSelection = useMemo(() => {
    return {
      suppressMultiRanges: true,
    };
  }, []);

  return {
    defaultColDef,
    columnTypes,
    dataTypeDefinitions,
    getRowId,
    onGridReady,
    sideBar,
    paginationPageSizeSelector,
    statusBar,
    rowSelection,
    selectionColumnDef,
    cellSelection,
  };
};
