import { Fragment, memo, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';

const MappingDropdown = memo(function MappingDropdown({
  items,
  title = 'More Actions',
}) {
  const [tooltipElement, setTooltipElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, tooltipElement, {
    placement: 'top',
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
  });

  const getMenuItemClass = ({ disabled, disabledReason }) =>
    `py-1 ${
      disabled
        ? disabledReason
          ? 'cursor-default'
          : 'pointer-events-none'
        : 'cursor-pointer'
    }`;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="dropdown-menu-button font-medium text-purple-vs-hover">
        {({ open }) => (
          <>
            <div>{title}</div>
            <div className={`arrow ${open ? 'up' : 'down'} border-purple-vs`} />
          </>
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="dropdown-menu-items w-56 divide-y divide-gray-100">
          {items.map((item) => (
            <div key={item.label} className={getMenuItemClass(item)}>
              <Menu.Item>
                {({ active }) => (
                  <span
                    ref={setReferenceElement}
                    className={`block px-4 py-2 text-13 ${
                      !item.disabled &&
                      active &&
                      'bg-neutral-100 text-gray-900 rounded'
                    } ${item.disabled ? 'text-gray-350' : 'text'}
                    ${
                      item.critical
                        ? 'hover:bg-red-200 hover:text-red text-red'
                        : 'text'
                    }
                    ${item.classes}`}
                    onClick={!item.disabled ? item.action : undefined}
                    onMouseEnter={() =>
                      item.disabledReason && setTooltipVisible(true)
                    }
                    onMouseLeave={() => setTooltipVisible(false)}
                  >
                    {item.label}
                  </span>
                )}
              </Menu.Item>
              {item.disabled && item.disabledReason && tooltipVisible && (
                <div
                  ref={setTooltipElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className="popper-status-tooltip"
                >
                  <div id="arrow" data-popper-arrow></div>
                  <p className="whitespace-normal">{item.disabledReason}</p>
                </div>
              )}
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
});

export default MappingDropdown;
