import { axiosSecureInstance } from './apiSecure';
import { paramsSerializer } from './serviceHelper.js';

const transformGenericMappingsResponse = (response) => {
  return response.map((obj) => {
    const { mappable_columns, ...rest } = obj;
    return {
      ...rest,
      ...mappable_columns,
    };
  });
};

export async function getGenericMappings(id, params) {
  const config = {
    params: params,
    paramsSerializer,
  };

  let mappings = await axiosSecureInstance.get(
    `/generic_mappings/${id}`,
    config
  );
  return transformGenericMappingsResponse(mappings.data); // We are getting mappable_columns under separate object attribute
}

export async function getGenericMappingsCount(id, params) {
  const config = {
    params: params,
    paramsSerializer,
  };

  let codeCount = await axiosSecureInstance.get(
    `/generic_mappings/code_count/${id}`,
    config
  );

  return codeCount.data.record_count;
}
