import {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import {
  CellSelectionModule,
  CellStyleModule,
  ClientSideRowModelModule,
  ColumnApiModule,
  ColumnMenuModule,
  ContextMenuModule,
  CsvExportModule,
  CustomFilterModule,
  FiltersToolPanelModule,
  GridStateModule,
  PaginationModule,
  QuickFilterModule,
  RenderApiModule,
  RowApiModule,
  RowAutoHeightModule,
  RowSelectionModule,
  StatusBarModule,
  TooltipModule,
} from 'ag-grid-enterprise';
import {
  claimJob,
  completeJob,
  completeMapping,
  deleteJob,
  downloadCompletedJob,
  getEarnedAmount,
  invoiceJobs,
  unhideJob,
} from '../../services/jobServices';
import { getJobsColumns } from './getJobsColumns';
import {
  onCellSelectionChanged,
  paginationNumberFormatter,
  sidebarDef,
  tableDefaultTheme,
} from '../../helpers/tableHelper';
import ColoredButton from '../common/ColoredButton';
import {
  activeJobStatuses,
  CLAIM_JOB_ACTION,
  COMPLETE_JOB_ACTION,
  COMPLETE_JOB_ACTION_LABEL,
  COMPLETE_JOB_PRACTICE_TYPE_MISSING_LABEL,
  COMPLETE_JOB_PRACTICE_TYPE_MISSING_NOT_ALLOWED_ACTION,
  COMPLETE_JOB_PRACTICE_TYPE_MISSING_TEXT,
  COMPLETE_JOB_PRACTICE_TYPE_MISSING_WARNING_ACTION,
  COMPLETE_MAPPING_ACTION,
  COMPLETE_MAPPING_ACTION_LABEL,
  COMPLETE_MAPPING_DIALOG_TEXT,
  COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_LABEL,
  COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_NOT_ALLOWED_ACTION,
  COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_TEXT,
  COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_WARRNING_TEXT,
  COMPLETE_MAPPING_PRACTICE_TYPE_WARNING_ACTION,
  COMPLETE_MAPPING_WARNING_DIALOG_IMPORTANT_TEXT,
  COMPLETE_MAPPING_WARNING_DIALOG_TEXT,
  COMPLETE_MAPPING_WARNING_JOB_ACTION,
  DELETE_JOB_ACTION,
  DOWNLOAD_CSV_ACTION,
  DOWNLOAD_CSV_JOB_ACTION_LABEL,
  DOWNLOAD_CSV_JOB_DIALOG_TEXT,
  getCompleteJobDialogText,
  getTimeSpentConfirmationDialogText,
  INVOICE_JOB_ACTION,
  INVOICE_JOB_ACTION_LABEL,
  INVOICE_JOB_DIALOG_TEXT,
  INVOICE_MULTIPLE_JOBS_ACTION,
  INVOICE_MULTIPLE_JOBS_ACTION_LABEL,
  INVOICE_MUTLIPLE_JOBS_DIALOG_TEXT,
  jobsHistoryPageFilters,
  jobsPageFilters,
  jobsPageFiltersMapper,
  jobsTableCapitalizedLetters,
  RATE_TYPE_PER_HOUR,
  UNHIDE_JOB_ACTION,
  UNHIDE_JOB_ACTION_LABEL,
  UNHIDE_JOB_DIALOG_TEXT,
} from '../../constants/jobConstants';
import ConfirmationModal from '../common/ConfirmationModal';
import { logger } from '../../services/logger';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  calculateAdditionalAdminColumns,
  falsyValue,
  formatHours,
  getBatchInvoiceJobsResultMessage,
  getMappingJobs,
} from '../../helpers/jobsTableHelper';
import CreateJobModal from './CreateJobModal';
import CustomJobForm from './forms/CustomJobForm';
import ClinicJobForm from './forms/ClinicJobForm';
import { jobsTableRefreshed } from '../../features/jobs-table/jobsTableSlice';
import {
  isAdmin,
  isMapper,
  isOnboardingUser,
  isSuperAdmin,
} from '../../helpers/userHelper';
import { renderJobPageFilterActions } from '../table/tableRenders';
import {
  collapseInactiveFilters,
  getActiveFiltersStatus,
  mapFilterLayout,
  resetHistoryFilters,
  resetJobsFilters,
} from '../../helpers/tableFiltersHelper';
import ActiveFilters from '../table/filters/ActiveFilters';
import PageSize from '../table/PageSize';
import {
  canMarkJobAsInvoiced,
  downloadJobsCSV,
  getAvailableMoneyAmountFromJobs,
} from '../../helpers/jobsHelper';
import downloadIcon from '../../assets/icons/download_file.svg';
import {
  DEFAULT_PAGE_SIZE,
  UP_DOWN_KEY_CODES,
  USER_CONFIG_TYPE_JOBS,
  USER_CONFIG_TYPE_JOBS_HISTORY,
} from '../../constants/constants';
import { openViewJobDetails } from '../../features/modal/modalActions';
import OutlineButton from '../common/OutlineButton';
import { ErrorContext } from '../../ErrorContext';
import { clientErrors } from '../../constants/errorConstants';
import UserTableConfiguration from '../mapping/UserTableConfiguration.jsx';
import {
  addDragEvents,
  didUserConfigChange,
  removeDragEvents,
  resetDragEvents,
} from '../../helpers/userConfigurationHelper.js';
import MoneyDisplay from './MoneyDisplay.jsx';

export default function JobsTable() {
  const location = useLocation();
  const isJobsPage = useMemo(() => location.pathname === '/jobs', []);
  const { setErrorAlert } = useContext(ErrorContext);
  const gridRef = useRef();
  const tableRef = useRef(null);
  const [rowData, setRowData] = useState();
  const [availablePractices, setAvailablePractices] = useState();
  const { userInfo } = useSelector((state) => state.user);
  const { userTableConfigurations, activeUserTableConfigurations } =
    useSelector((state) => state.userConfig);
  const configType = useMemo(
    () => (isJobsPage ? USER_CONFIG_TYPE_JOBS : USER_CONFIG_TYPE_JOBS_HISTORY),
    []
  );
  const activeUserTableConfiguration = useMemo(
    () => activeUserTableConfigurations?.[configType],
    [activeUserTableConfigurations]
  );
  const [isTableReady, setIsTableReady] = useState(false);
  const [selectionInProgress, setSelectionInProgress] = useState(false);
  const [notInvoicedJobIds, setNotInvoicedJobIds] = useState([]);
  const [noJobMetInvoiceCriteria, setNoJobMetInvoiceCriteria] = useState(false);
  const { users, usersWithoutAutomapper, practices } = useSelector(
    (state) => state.tableData
  );
  const { refreshJobsTable } = useSelector((state) => state.jobsTable);
  const [jobAction, setJobAction] = useState(null);
  const [columnDefs] = useState(
    getJobsColumns(
      { setJobAction },
      { setErrorAlert },
      { location },
      { usersWithoutAutomapper }
    )
  );
  const [addJobOpen, setAddJobOpen] = useState(false);
  const [isDataRendered, setDataRendered] = useState(false);
  const [activeFiltersStatus, setActiveFiltersStatus] = useState([]);
  const [confirmationButtonDisabled, setConfirmationButtonDisabled] =
    useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fullBackendResponse, setFullBackendResponse] = useState(null);
  const [currentPageSize, setCurrentPageSize] = useState(
    activeUserTableConfiguration?.configuration.pageSize || DEFAULT_PAGE_SIZE
  );
  const [isUserConfigChanged, setIsUserConfigChanged] = useState(false);
  const [earnedAmount, setEarnedAmount] = useState(null);
  const [availableAmount, setAvailableAmount] = useState(null);
  const [isClearSortVisible, setIsClearSortVisible] = useState(false);

  const sideBar = useMemo(() => {
    return sidebarDef;
  }, []);

  const defaultFilterLayout = useMemo(() => {
    if (isJobsPage) {
      return isAdmin(userInfo) ? jobsPageFilters : jobsPageFiltersMapper;
    }
    return jobsHistoryPageFilters;
  }, []);

  const filterLayout = useRef(
    activeUserTableConfiguration
      ? activeUserTableConfiguration.configuration.filterOrder
      : defaultFilterLayout
  );

  useEffect(() => {
    return () => {
      removeDragEvents();
    };
  }, []);

  useEffect(() => {
    //we need to rerender columns that have cellRenderer - since it is not being triggered when setRowData is done
    if (gridRef.current?.api) {
      gridRef.current.api.refreshCells({
        force: true,
        columns: [
          'action',
          'rate',
          'status',
          'mapped_code_count',
          'project_type',
        ],
      });
    }
  }, [rowData, gridRef.current?.api]);

  useEffect(() => {
    if (gridRef.current?.api && refreshJobsTable) {
      updateGridRows();
    }
    dispatch(jobsTableRefreshed());
  }, [refreshJobsTable, gridRef.current?.api]);

  useEffect(() => {
    gridRef.current.api?.setGridOption('paginationPageSize', currentPageSize);
    gridRef.current.api?.deselectAll();
  }, [currentPageSize]);

  useEffect(() => {
    if (isMapper(userInfo)) {
      updateEarnedAmount();
    }
  }, [userInfo, isJobsPage]);

  useEffect(() => {
    const newAvailableAmount = getAvailableMoneyAmountFromJobs(rowData);
    setAvailableAmount(newAvailableAmount);
  }, [rowData]);

  const setPageSize = (value) => {
    setCurrentPageSize(value);
    setIsUserConfigChanged(true);
  };

  const onFirstDataRendered = useCallback(
    (e) => {
      e.api.onFilterChanged();
      if (activeUserTableConfiguration) {
        gridRef.current.api.applyColumnState({
          state: activeUserTableConfiguration.configuration.columnState,
          applyOrder: true,
        });
      }
      addDragEvents(tableRef, filterLayout, setIsUserConfigChanged);
      setDataRendered(true);
      //to avoid rows flickering after first render and filter, we are waiting for filtered data to be rendered and than we set row data with full backend response
      if (isJobsPage) setRowData(fullBackendResponse);
    },
    [fullBackendResponse]
  );

  const onDragStarted = useCallback(() => {
    setSelectionInProgress(true);
  }, []);

  const onDragStopped = useCallback(() => {
    setSelectionInProgress(false);
  }, []);

  const onCellKeyDown = useCallback(({ api, event }) => {
    if (event.shiftKey && UP_DOWN_KEY_CODES.includes(event.keyCode)) {
      setSelectionInProgress(true);
    }
    if (event.ctrlKey) {
      switch (event.key) {
        case '1':
          {
            let selectedInvoicableJobIds = api
              .getSelectedNodes()
              .filter((node) => canMarkJobAsInvoiced(node.data, userInfo))
              .map((node) => node.data.id);
            setJobAction({
              action: INVOICE_MULTIPLE_JOBS_ACTION,
              jobIds: selectedInvoicableJobIds,
            });
          }
          break;
      }
    }
  }, []);

  const resetFilters = useCallback(
    isJobsPage ? resetJobsFilters : resetHistoryFilters,
    []
  );

  const createFilterActions = () => {
    if (document.querySelector('.ag-tool-panel-wrapper')) {
      return createPortal(
        renderJobPageFilterActions(
          gridRef.current.api,
          false,
          !isDataRendered,
          resetFilters,
          resetQuickFilter,
          onSortReset,
          isClearSortVisible
        ),
        document.querySelector('.ag-tool-panel-wrapper')
      );
    }
  };

  const createFilterStatus = () => {
    return createPortal(
      <ActiveFilters status={activeFiltersStatus} />,
      document.querySelector('.ag-tool-panel-wrapper')
    );
  };

  const defaultColDef = useMemo(() => {
    return {
      wrapHeaderText: true,
      suppressFillHandle: true,
      flex: 1,
      filterParams: { clientSide: true },
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        if (falsyValue(valueA) && falsyValue(valueB)) {
          return 0;
        }
        if (falsyValue(valueA)) {
          return isInverted ? -1 : 1;
        }
        if (falsyValue(valueB)) {
          return isInverted ? 1 : -1;
        }

        return typeof valueA === 'number' && typeof valueB === 'number'
          ? valueA - valueB
          : valueA.localeCompare(valueB);
      },
      valueFormatter: (params) =>
        params.value ? formattedCellValue(params) : '\u2014',
    };
  }, []);

  const formattedCellValue = (params) => {
    if (jobsTableCapitalizedLetters.includes(params.column.colId)) {
      let value = params.value;
      let firstLetter = value.slice(0, 1).toUpperCase();
      return firstLetter + value.slice(1);
    } else return params.value;
  };

  const onGridReady = useCallback((e) => {
    e.api.resetColumnState();

    const filtersToolPanel = e.api.getToolPanelInstance('filters');
    filtersToolPanel.setFilterLayout(mapFilterLayout(filterLayout.current));
    if (isJobsPage) filtersToolPanel.expandFilters(['status']);
    e.api.closeToolPanel();

    isJobsPage
      ? getMappingJobs[location.pathname](
          userInfo,
          practices,
          setRowData,
          setErrorAlert,
          setAvailablePractices,
          setFullBackendResponse,
          users
        )
      : getMappingJobs[location.pathname](setRowData, setErrorAlert);

    setIsTableReady(true);
  }, []);

  const updateGridRows = () => {
    gridRef.current.api.applyColumnState({
      defaultState: { sort: null },
    });
    gridRef.current.api.deselectAll();
    isJobsPage
      ? getMappingJobs[location.pathname](
          userInfo,
          practices,
          setRowData,
          setErrorAlert,
          setAvailablePractices,
          null,
          users
        )
      : getMappingJobs[location.pathname](setRowData, setErrorAlert);
  };

  const onSortChanged = useCallback((e) => {
    e.api.deselectAll();
    e.api.paginationGoToFirstPage();
    setIsClearSortVisible(e.api.getColumnState().some((c) => c.sort));
    didUserConfigChangeCallback(e);
  }, []);

  const onSortReset = useCallback(() => {
    gridRef.current.api.applyColumnState({
      defaultState: { sort: null },
    });
    setIsUserConfigChanged(true);
  }, [gridRef.current?.api]);

  const getRowId = useCallback((params) => {
    return params.data.id.toString();
  }, []);

  function updateEarnedAmount() {
    getEarnedAmount()
      .then((res) => {
        setEarnedAmount(res.earned_amount);
      })
      .catch((error) => {
        setEarnedAmount(null);
        logger.catchError(error);
      });
  }

  function getActionHandler(actionFn) {
    return ({ jobId, action }) => {
      setConfirmationButtonDisabled(true);
      actionFn(jobId)
        .then((res) => {
          if (action === DOWNLOAD_CSV_ACTION) {
            if (res.data.download_url) {
              const link = document.createElement('a');
              link.href = res.data.download_url;
              document.body.appendChild(link);
              link.click();
            } else setErrorAlert({ parsedError: clientErrors.completedJobCsv });
          } else if (action === CLAIM_JOB_ACTION && isMapper(userInfo)) {
            navigate(`/jobmapping/${jobId}`);
          } else if (action !== DELETE_JOB_ACTION) {
            let node = gridRef.current.api.getRowNode(jobId);
            node.setData(
              isAdmin(userInfo)
                ? calculateAdditionalAdminColumns(
                    [res.data],
                    users,
                    practices
                  )[0]
                : res.data
            );
            gridRef.current.api.flashCells({ rowNodes: [node] });
            if (
              [
                COMPLETE_MAPPING_ACTION,
                COMPLETE_MAPPING_WARNING_JOB_ACTION,
              ].includes(action) &&
              isMapper(userInfo)
            ) {
              updateEarnedAmount();
            }
          } else {
            gridRef.current.api.applyTransaction({ remove: [{ id: jobId }] });
            let activeJobPracticeIds = [];
            gridRef.current.api.forEachNode((jobNode) => {
              if (activeJobStatuses.includes(jobNode.data.status)) {
                activeJobPracticeIds.push(jobNode.data.practice_id);
              }
            });
            setAvailablePractices(
              practices.filter(
                (practice) => !activeJobPracticeIds.includes(practice.id)
              )
            );
          }
        })
        .catch((error) => {
          setErrorAlert({
            error: error,
            onButtonClick: () => updateGridRows(),
            onClose: () => updateGridRows(),
          });
        })
        .finally(() => {
          setJobAction(null);
          setConfirmationButtonDisabled(false);
        });
    };
  }

  function getInvoiceActionHandler() {
    return ({ jobIds }) => {
      setConfirmationButtonDisabled(true);
      if (jobIds.length > 0) {
        invoiceJobs(jobIds)
          .then((res) => {
            let updatedNodes = [];
            jobIds.forEach((jobId) => {
              let node = gridRef.current.api.getRowNode(jobId);
              let updatedRowData = calculateAdditionalAdminColumns(
                [res.find((rowData) => rowData.id === jobId)],
                users,
                practices
              );
              node.setData(updatedRowData[0]);
              updatedNodes.push(node);
            });
            gridRef.current.api.flashCells({ rowNodes: updatedNodes });
            setNotInvoicedJobIds(
              gridRef.current.api
                .getSelectedNodes()
                .filter((node) => !jobIds.includes(node.data.id))
                .map((node) => node.data.id)
            );
          })
          .catch((error) => {
            logger.catchError(error);
            setErrorAlert({
              parsedError: clientErrors.ctaError,
              onButtonClick: () => updateGridRows(),
              buttonText: 'Refresh',
              ctaButton: true,
              closingButton: true,
            });
          })
          .finally(() => {
            setJobAction(null);
            setConfirmationButtonDisabled(false);
            setNoJobMetInvoiceCriteria(false);
          });
      } else {
        //none of the selected jobs are invoicable
        setNoJobMetInvoiceCriteria(true);
        setNotInvoicedJobIds(
          gridRef.current.api
            .getSelectedNodes()
            .filter((node) => !jobIds.includes(node.data.id))
            .map((node) => node.data.id)
        );
        setJobAction(null);
        setConfirmationButtonDisabled(false);
      }
    };
  }

  const onFilterChanged = useCallback(({ api, source }) => {
    if (source === 'api') {
      collapseInactiveFilters(api, filterLayout.current);
    }
    api.deselectAll();
    setActiveFiltersStatus(getActiveFiltersStatus(api.getFilterModel()));
    if (api.paginationGetRowCount() === 0) {
      api.showNoRowsOverlay();
    } else {
      api.hideOverlay();
    }
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setGridOption(
      'quickFilterText',
      document.getElementById('filter-text-box').value
    );
  }, []);

  const resetQuickFilter = useCallback(() => {
    document.getElementById('filter-text-box').value = null;
    gridRef.current.api.setGridOption('quickFilterText', null);
  }, [gridRef.current?.api]);

  const onPaginationChanged = useCallback((event) => {
    if (event.newPage) event.api.deselectAll();
  }, []);

  const getContextMenuItems = useCallback(
    (e) =>
      isSuperAdmin(userInfo)
        ? [
            {
              name: 'Invoice Jobs',
              shortcut: 'Ctrl + 1',
              action: () => {
                let selectedInvoicableJobIds = e.api
                  .getSelectedNodes()
                  .filter((node) => canMarkJobAsInvoiced(node.data, userInfo))
                  .map((node) => node.data.id);
                setJobAction({
                  action: INVOICE_MULTIPLE_JOBS_ACTION,
                  jobIds: selectedInvoicableJobIds,
                });
              },
            },
          ]
        : null,
    []
  );

  const onCellContextMenu = useCallback((event) => {
    event.node.setSelected(true);
  }, []);

  const onCellSelectionChangedCallback = useCallback(
    (event) => {
      onCellSelectionChanged(event, selectionInProgress);
    },
    [selectionInProgress]
  );

  const didUserConfigChangeCallback = useCallback((e) => {
    didUserConfigChange(e, setIsUserConfigChanged);
  }, []);

  const resetDragEventsCallback = () => {
    resetDragEvents(tableRef, filterLayout, setIsUserConfigChanged);
  };

  const createUserTableConfigPortal = () => {
    if (isTableReady && document.querySelector('#right-section-placeholder')) {
      return createPortal(
        <>
          <UserTableConfiguration
            configType={configType}
            maxLayouts={5}
            gridApi={gridRef.current.api}
            setCurrentPageSize={setCurrentPageSize}
            filterLayout={filterLayout}
            defaultFilterLayout={defaultFilterLayout}
            configs={userTableConfigurations}
            activeConfig={activeUserTableConfiguration}
            isEdited={isUserConfigChanged}
            setIsEdited={setIsUserConfigChanged}
            resetDragEvents={resetDragEventsCallback}
            setAlertError={setErrorAlert}
          />
        </>,
        document.querySelector('#right-section-placeholder')
      );
    }
  };

  const createUserTableConfig = useMemo(
    () => createUserTableConfigPortal(),
    [
      gridRef.current?.api,
      isTableReady,
      isUserConfigChanged,
      userTableConfigurations,
    ]
  );

  const rowSelection = useMemo(() => {
    return {
      mode: 'multiRow',
      enableClickSelection: true,
      checkboxes: false,
      headerCheckbox: false,
    };
  }, []);

  const cellSelection = useMemo(() => {
    return {
      suppressMultiRanges: true,
    };
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: 'agSelectedRowCountComponent',
        },
        {
          statusPanel: 'agAggregationComponent',
          statusPanelParams: {
            aggFuncs: ['sum'],
          },
        },
      ],
    };
  }, []);

  return (
    <>
      <div className="mx-4 flex items-center justify-between">
        <div className="pr-2 flex items-center w-1/3 max-w-sm py-2">
          <input
            type="text"
            id="filter-text-box"
            placeholder="Search by any field..."
            className="max-w-sm mr-5"
            onInput={onFilterTextBoxChanged}
          />
          <div
            className="flex text-sm items-center max-w-sm cursor-pointer"
            onClick={() => downloadJobsCSV(gridRef.current.api, isJobsPage)}
          >
            <img className="mr-2 w-5" src={downloadIcon} alt="download-icon" />
            <div className="w-28">Download CSV</div>
          </div>
        </div>
        <div className="flex w-2/3 justify-end items-center">
          {isJobsPage && isMapper(userInfo) && (
            <MoneyDisplay value={availableAmount} variant="available" />
          )}
          {typeof earnedAmount === 'number' && isMapper(userInfo) && (
            <MoneyDisplay value={earnedAmount} variant="earned" />
          )}
          <div
            className="flex justify-end items-center w-[250px]"
            id="right-section-placeholder"
          />
          {isJobsPage && isAdmin(userInfo) && (
            <div className="flex items-center">
              <ColoredButton
                padding="px-1"
                text="New Job"
                widthClass="w-[100px]"
                onClick={() => setAddJobOpen(true)}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="flex mx-4 mb-4 flex-grow relative overflow-hidden"
        id="jobsTableContainer"
      >
        <div id="jobsTable" className="w-full h-full">
          <div
            data-testid="jobsPageTable"
            ref={tableRef}
            className={isDataRendered ? '' : ' data-loading'}
            style={{ height: '100%', width: '100%' }}
          >
            <AgGridReact
              modules={[
                CellSelectionModule,
                CellStyleModule,
                ClientSideRowModelModule,
                ColumnApiModule,
                ColumnMenuModule,
                ContextMenuModule,
                CsvExportModule,
                CustomFilterModule,
                FiltersToolPanelModule,
                GridStateModule,
                PaginationModule,
                QuickFilterModule,
                RenderApiModule,
                RowApiModule,
                RowAutoHeightModule,
                RowSelectionModule,
                TooltipModule,
                StatusBarModule,
              ]}
              ref={gridRef}
              columnDefs={columnDefs}
              getRowId={getRowId}
              defaultColDef={defaultColDef}
              rowData={rowData}
              headerHeight={45}
              rowHeight={45}
              paginationPageSize={currentPageSize}
              paginationPageSizeSelector={false}
              suppressRowTransform={true}
              onPaginationChanged={onPaginationChanged}
              onGridReady={onGridReady}
              getContextMenuItems={getContextMenuItems}
              onCellContextMenu={onCellContextMenu}
              onDragStopped={onDragStopped}
              onDragStarted={onDragStarted}
              onCellSelectionChanged={onCellSelectionChangedCallback}
              onCellKeyDown={onCellKeyDown}
              rowSelection={rowSelection}
              cellSelection={cellSelection}
              pagination={true}
              paginationNumberFormatter={paginationNumberFormatter}
              alwaysMultiSort
              onSortChanged={onSortChanged}
              sideBar={sideBar}
              statusBar={statusBar}
              onFirstDataRendered={onFirstDataRendered}
              onFilterChanged={onFilterChanged}
              overlayLoadingTemplate={
                '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
              }
              tooltipShowDelay={500}
              onColumnResized={didUserConfigChangeCallback}
              onColumnVisible={didUserConfigChangeCallback}
              onColumnMoved={didUserConfigChangeCallback}
              columnMenu={'legacy'}
              theme={tableDefaultTheme}
            ></AgGridReact>
            <PageSize
              currentPageSize={currentPageSize}
              setPageSize={setPageSize}
            />
          </div>
          {isTableReady && createFilterActions()}
          {isTableReady && createFilterStatus()}
          {createUserTableConfig}
          {jobAction && jobAction.action === CLAIM_JOB_ACTION && (
            <ConfirmationModal
              isOpen={jobAction && jobAction.action === CLAIM_JOB_ACTION}
              title="Claim a job"
              text={`Are you sure that you want to claim job #${jobAction.jobId}?`}
              buttonText="Confirm"
              onClose={() => setJobAction(null)}
              onButtonClick={() => {
                getActionHandler(claimJob)(jobAction);
              }}
              buttonsDisabled={confirmationButtonDisabled}
            />
          )}
          {jobAction && jobAction.action === COMPLETE_MAPPING_ACTION && (
            <ConfirmationModal
              title={COMPLETE_MAPPING_ACTION_LABEL}
              text={
                (jobAction.jobInfo &&
                jobAction.jobInfo.rate_type === RATE_TYPE_PER_HOUR
                  ? getTimeSpentConfirmationDialogText(
                      formatHours(jobAction.jobInfo.mapped_time)
                    )
                  : '') + COMPLETE_MAPPING_DIALOG_TEXT
              }
              buttonText="Yes"
              onButtonClick={() => {
                getActionHandler(completeMapping)(jobAction);
              }}
              onClose={() => setJobAction(null)}
              isOpen={jobAction && jobAction.action === COMPLETE_MAPPING_ACTION}
              buttonsDisabled={confirmationButtonDisabled}
            />
          )}
          {jobAction &&
            jobAction.action === COMPLETE_MAPPING_WARNING_JOB_ACTION && (
              <ConfirmationModal
                title={COMPLETE_MAPPING_ACTION_LABEL}
                importantText={COMPLETE_MAPPING_WARNING_DIALOG_IMPORTANT_TEXT}
                text={
                  (jobAction.jobInfo &&
                  jobAction.jobInfo.rate_type === RATE_TYPE_PER_HOUR
                    ? getTimeSpentConfirmationDialogText(
                        formatHours(jobAction.jobInfo.mapped_time)
                      )
                    : '') + COMPLETE_MAPPING_WARNING_DIALOG_TEXT
                }
                buttonText="Yes"
                operationType="warning"
                onButtonClick={() => {
                  getActionHandler(completeMapping)(jobAction);
                }}
                onClose={() => setJobAction(null)}
                isOpen={
                  jobAction &&
                  jobAction.action === COMPLETE_MAPPING_WARNING_JOB_ACTION
                }
                buttonsDisabled={confirmationButtonDisabled}
              />
            )}
          {jobAction &&
            jobAction.action ===
              COMPLETE_MAPPING_PRACTICE_TYPE_WARNING_ACTION && (
              <ConfirmationModal
                title={COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_LABEL}
                text={COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_WARRNING_TEXT}
                buttonText="Complete Anyway"
                customButtons={() => {
                  return (
                    <>
                      <OutlineButton
                        text="Open Job Details"
                        heightClass="h-10"
                        widthClass="w-[150px]"
                        disabled={confirmationButtonDisabled}
                        onClick={async () => {
                          setJobAction(null);
                          try {
                            await dispatch(
                              openViewJobDetails(jobAction.jobId)
                            ).unwrap();
                          } catch (error) {
                            setErrorAlert({ parsedError: error });
                          }
                        }}
                      />
                      <ColoredButton
                        text="Complete Anyway"
                        heightClass="h-10"
                        widthClass="w-[150px]"
                        fillColorClass="bg-orange"
                        border="border border-solid border-orange outline-none"
                        hoverColorClass="hover:bg-orange-hover"
                        disabled={confirmationButtonDisabled}
                        onClick={() => {
                          setJobAction({
                            action: COMPLETE_MAPPING_ACTION,
                            jobId: jobAction.jobId,
                            jobInfo: jobAction.jobInfo,
                          });
                        }}
                      />
                    </>
                  );
                }}
                onClose={async () => {
                  setJobAction(null);
                  try {
                    await dispatch(
                      openViewJobDetails(jobAction.jobId)
                    ).unwrap();
                  } catch (error) {
                    setErrorAlert({ parsedError: error });
                  }
                }}
                isOpen={
                  jobAction &&
                  jobAction.action ===
                    COMPLETE_MAPPING_PRACTICE_TYPE_WARNING_ACTION
                }
              />
            )}
          {jobAction &&
            jobAction.action ===
              COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_NOT_ALLOWED_ACTION && (
              <ConfirmationModal
                title={COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_LABEL}
                text={COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_TEXT}
                operationType="warning"
                customButtons={() => {
                  return (
                    <OutlineButton
                      text="Open Job Details"
                      heightClass="h-10"
                      widthClass="w-[150px]"
                      disabled={confirmationButtonDisabled}
                      onClick={async () => {
                        setJobAction(null);
                        try {
                          await dispatch(
                            openViewJobDetails(jobAction.jobId)
                          ).unwrap();
                        } catch (error) {
                          setErrorAlert({ parsedError: error });
                        }
                      }}
                    />
                  );
                }}
                onClose={() => setJobAction(null)}
                isOpen={
                  jobAction &&
                  jobAction.action ===
                    COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_NOT_ALLOWED_ACTION
                }
              />
            )}
          {jobAction &&
            jobAction.action ===
              COMPLETE_JOB_PRACTICE_TYPE_MISSING_NOT_ALLOWED_ACTION && (
              <ConfirmationModal
                title={COMPLETE_JOB_PRACTICE_TYPE_MISSING_LABEL}
                text={COMPLETE_JOB_PRACTICE_TYPE_MISSING_TEXT}
                operationType="warning"
                customButtons={() => {
                  return (
                    <OutlineButton
                      text="Open Job Details"
                      heightClass="h-10"
                      widthClass="w-[150px]"
                      onClick={async () => {
                        setJobAction(null);
                        try {
                          await dispatch(
                            openViewJobDetails(jobAction.jobId)
                          ).unwrap();
                        } catch (error) {
                          setErrorAlert({ parsedError: error });
                        }
                      }}
                    />
                  );
                }}
                onClose={() => setJobAction(null)}
                isOpen={
                  jobAction &&
                  jobAction.action ===
                    COMPLETE_JOB_PRACTICE_TYPE_MISSING_NOT_ALLOWED_ACTION
                }
                buttonsDisabled={confirmationButtonDisabled}
              />
            )}
          {jobAction &&
            jobAction.action ===
              COMPLETE_JOB_PRACTICE_TYPE_MISSING_WARNING_ACTION && (
              <ConfirmationModal
                title={COMPLETE_JOB_PRACTICE_TYPE_MISSING_LABEL}
                text={COMPLETE_JOB_PRACTICE_TYPE_MISSING_TEXT}
                buttonText="Complete Anyway"
                customButtons={() => {
                  return (
                    <>
                      <OutlineButton
                        text="Open Job Details"
                        heightClass="h-10"
                        widthClass="w-[150px]"
                        onClick={async () => {
                          setJobAction(null);
                          try {
                            await dispatch(
                              openViewJobDetails(jobAction.jobId)
                            ).unwrap();
                          } catch (error) {
                            setErrorAlert({ parsedError: error });
                          }
                        }}
                      />
                      <ColoredButton
                        text="Complete Anyway"
                        heightClass="h-10"
                        widthClass="w-[150px]"
                        fillColorClass="bg-orange"
                        border="border border-solid border-orange outline-none"
                        hoverColorClass="hover:bg-orange-hover"
                        onClick={() => {
                          setJobAction({
                            action: COMPLETE_JOB_ACTION,
                            jobId: jobAction.jobId,
                          });
                        }}
                      />
                    </>
                  );
                }}
                onClose={async () => {
                  setJobAction(null);
                  try {
                    await dispatch(
                      openViewJobDetails(jobAction.jobId)
                    ).unwrap();
                  } catch (error) {
                    setErrorAlert({ parsedError: error });
                  }
                }}
                isOpen={
                  jobAction &&
                  jobAction.action ===
                    COMPLETE_JOB_PRACTICE_TYPE_MISSING_WARNING_ACTION
                }
                buttonsDisabled={confirmationButtonDisabled}
              />
            )}
          {jobAction && jobAction.action === COMPLETE_JOB_ACTION && (
            <ConfirmationModal
              title={COMPLETE_JOB_ACTION_LABEL}
              text={getCompleteJobDialogText(jobAction.jobId)}
              buttonText="Yes"
              onButtonClick={() => {
                getActionHandler(completeJob)(jobAction);
              }}
              onClose={() => setJobAction(null)}
              isOpen={jobAction && jobAction.action === COMPLETE_JOB_ACTION}
              buttonsDisabled={confirmationButtonDisabled}
            />
          )}
          {jobAction && jobAction.action === INVOICE_JOB_ACTION && (
            <ConfirmationModal
              title={INVOICE_JOB_ACTION_LABEL}
              text={INVOICE_JOB_DIALOG_TEXT}
              buttonText="Yes, invoice"
              onButtonClick={() => {
                getInvoiceActionHandler()(jobAction);
              }}
              onClose={() => setJobAction(null)}
              isOpen={jobAction && jobAction.action === INVOICE_JOB_ACTION}
              buttonsDisabled={confirmationButtonDisabled}
            />
          )}
          {jobAction && jobAction.action === INVOICE_MULTIPLE_JOBS_ACTION && (
            <ConfirmationModal
              title={INVOICE_MULTIPLE_JOBS_ACTION_LABEL}
              text={INVOICE_MUTLIPLE_JOBS_DIALOG_TEXT}
              buttonText="Yes, invoice"
              onButtonClick={() => {
                getInvoiceActionHandler()(jobAction);
              }}
              onClose={() => setJobAction(null)}
              isOpen={
                jobAction && jobAction.action === INVOICE_MULTIPLE_JOBS_ACTION
              }
              buttonsDisabled={confirmationButtonDisabled}
            />
          )}
          {notInvoicedJobIds.length > 0 && (
            <ConfirmationModal
              title={
                noJobMetInvoiceCriteria
                  ? 'No job invoiced'
                  : 'Partially completed'
              }
              text={getBatchInvoiceJobsResultMessage(
                notInvoicedJobIds,
                noJobMetInvoiceCriteria
              )}
              buttonText="Ok"
              showCancel={false}
              onButtonClick={() => {
                setNotInvoicedJobIds([]);
                setNoJobMetInvoiceCriteria(false);
              }}
              onClose={() => {
                setNotInvoicedJobIds([]);
                setNoJobMetInvoiceCriteria(false);
              }}
              isOpen={notInvoicedJobIds.length > 0}
              buttonsDisabled={confirmationButtonDisabled}
            />
          )}
          {jobAction && jobAction.action === DOWNLOAD_CSV_ACTION && (
            <ConfirmationModal
              title={DOWNLOAD_CSV_JOB_ACTION_LABEL}
              text={DOWNLOAD_CSV_JOB_DIALOG_TEXT}
              buttonText="Yes, download"
              onButtonClick={() => {
                getActionHandler(downloadCompletedJob)(jobAction);
              }}
              onClose={() => setJobAction(null)}
              isOpen={jobAction && jobAction.action === DOWNLOAD_CSV_ACTION}
              buttonsDisabled={confirmationButtonDisabled}
            />
          )}
          {jobAction && jobAction.action === DELETE_JOB_ACTION && (
            <ConfirmationModal
              title="Delete job"
              text={`Are you sure you want to delete job #${jobAction.jobId} ?`}
              buttonText="Yes, I’m sure"
              operationType="danger"
              onButtonClick={() => {
                getActionHandler(deleteJob)(jobAction);
              }}
              onClose={() => setJobAction(null)}
              isOpen={jobAction && jobAction.action === DELETE_JOB_ACTION}
              buttonsDisabled={confirmationButtonDisabled}
            />
          )}
          {jobAction && jobAction.action === UNHIDE_JOB_ACTION && (
            <ConfirmationModal
              title={UNHIDE_JOB_ACTION_LABEL}
              text={UNHIDE_JOB_DIALOG_TEXT}
              buttonText="Yes, unhide"
              onButtonClick={() => {
                getActionHandler(unhideJob)(jobAction);
              }}
              onClose={() => setJobAction(null)}
              isOpen={jobAction && jobAction.action === UNHIDE_JOB_ACTION}
              buttonsDisabled={confirmationButtonDisabled}
            />
          )}
          <CreateJobModal
            openState={addJobOpen}
            onClose={() => setAddJobOpen(false)}
            title="Create Job"
            customJobForm={
              isOnboardingUser(userInfo) ? undefined : (
                <CustomJobForm
                  onCancel={() => setAddJobOpen(false)}
                  onSave={updateGridRows}
                  onJobStatusFailed={setErrorAlert}
                />
              )
            }
            clinicJobForm={
              <ClinicJobForm
                onCancel={() => setAddJobOpen(false)}
                availablePractices={availablePractices}
                onSave={updateGridRows}
              />
            }
          />
        </div>
      </div>
    </>
  );
}
