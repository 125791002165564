import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

export const ColoredSelect = ({
  options,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <Menu as="div" className="relative w-52 text-left">
      <Menu.Button className="dropdown-menu-button px-3 py-1 border rounded">
        {({ open }) => (
          <>
            {selectedOption ? (
              <div className="inline-flex items-center">
                <span
                  className={`w-4 h-4 rounded-full mr-2 ${selectedOption.color}`}
                />
                {selectedOption.name}
              </div>
            ) : (
              <span className="text-gray-400">Choose mapping type</span>
            )}
            <span
              className={`arrow ${open ? 'up' : 'down'} border-purple-vs`}
            />
          </>
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="dropdown-menu-items w-full cursor-pointer">
          {options.map((option) => (
            <Menu.Item key={option.id}>
              {({ active }) => (
                <div
                  className={`flex items-center justify-between ${
                    active && 'bg-neutral-100'
                  } ${option.id === selectedOption?.id && 'bg-neutral-200'}`}
                >
                  <div
                    className={
                      'flex-auto inline-flex items-center block px-4 py-2.5 text-13 truncate'
                    }
                    onClick={() => setSelectedOption(option)}
                    title={option.name}
                  >
                    <span
                      className={`w-4 h-4 rounded-full mr-2 ${option.color}`}
                    />
                    {option.name}
                  </div>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
