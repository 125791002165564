import { useState } from 'react';
import Select, { createFilter } from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { parseError } from '../../../../helpers/errorHelper';
import ColoredButton from '../../../common/ColoredButton';
import OutlineButton from '../../../common/OutlineButton';
import ConfirmationModal from '../../../common/ConfirmationModal';
import { getExcludedResourceConfirmationText } from '../../../../helpers/modalHelper';
import { saveExcludedResource } from '../../../../services/excludedResourcesServices';
import AsyncSelect from 'react-select/async';
import {
  digitRgx,
  GROUP_RESOURCE_TYPE,
  PRACTICE_RESOURCE_TYPE,
} from '../../../../constants/constants';
import { WindowedMenuList } from 'react-windowed-select';
import {
  isGroupResourceType,
  isPracticeResourceType,
} from '../../../../helpers/commonHelper';

export default function ExcludeResourceForm({
  groups,
  practices,
  onFinish,
  onClose,
}) {
  const [resourceType, setResourceType] = useState(GROUP_RESOURCE_TYPE);
  const [serverError, setServerError] = useState(null);
  const [excludeResource, setExcludeResource] = useState(null);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  const submitForm = (data) => {
    data.resource.type = resourceType;
    setExcludeResource({ ...data.resource, comment: data.comment });
  };

  const onResourceChange = (resourceType) => {
    setResourceType(resourceType);
    setServerError(null);
    reset();
  };

  const onConfirm = () => {
    saveExcludedResource({
      resource_type: resourceType,
      resource_id: excludeResource.id,
      comment: excludeResource.comment,
    })
      .then(({ data }) => {
        setExcludeResource(null);
        onFinish(data);
      })
      .catch((error) => {
        setExcludeResource(null);
        setServerError(parseError(error).description);
      });
  };

  const filterOptions = (inputValue) => {
    const isValid =
      inputValue && (inputValue.match(digitRgx) || inputValue.length >= 3);
    return isValid
      ? practices.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      : [];
  };

  const loadOptions = (inputValue, callback) => {
    callback(filterOptions(inputValue));
  };

  return (
    <>
      <div className="text-center text-14 text-gray-400">
        Search for a Practice name or Group
      </div>
      <form className="mt-6" onSubmit={handleSubmit(submitForm)}>
        <div className="relative mb-8">
          <div className="text-14 text-gray-400">
            <span
              className={`px-2.5 pb-1.5 cursor-pointer ${
                isGroupResourceType(resourceType)
                  ? 'text-black border-b-[1px] border-black'
                  : ''
              }`}
              onClick={() => onResourceChange(GROUP_RESOURCE_TYPE)}
            >
              Groups
            </span>
            <span
              className={`px-3 pb-1.5 cursor-pointer ${
                isPracticeResourceType(resourceType)
                  ? 'text-black border-b-[1px] border-black'
                  : ''
              }`}
              onClick={() => onResourceChange(PRACTICE_RESOURCE_TYPE)}
            >
              Practices
            </span>
          </div>
          {isGroupResourceType(resourceType) && (
            <Controller
              name="resource"
              control={control}
              rules={{
                required: 'Group is required',
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  id="resource"
                  inputId="resource-id"
                  className="formInput !border-0"
                  isClearable={true}
                  options={groups}
                />
              )}
            />
          )}
          {isPracticeResourceType(resourceType) && (
            <Controller
              id="resource"
              name="resource"
              control={control}
              rules={{
                required: 'Practice is required',
              }}
              render={({ field }) => (
                <AsyncSelect
                  defaultOptions
                  cacheOptions
                  loadOptions={loadOptions}
                  components={{ MenuList: WindowedMenuList }}
                  noOptionsMessage={() => null}
                  filterOption={createFilter({ ignoreAccents: false })}
                  id="resource"
                  inputId="resource-id"
                  {...field}
                  className="formInput !border-0"
                  isClearable={true}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  options={practices}
                />
              )}
            />
          )}
          <div className="relative">
            <Controller
              name="comment"
              id="comment"
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  id="comment"
                  type="text"
                  className="textarea mt-6"
                />
              )}
            />
            <label htmlFor="comment" className="formLabel -mt-3">
              Comment (optional)
            </label>
          </div>
          <label htmlFor="resource-id" className="hidden">
            Practice Group
          </label>
          {errors.resource?.type === 'required' && (
            <p className="formError">{errors.resource.message}</p>
          )}
          <p className="formError text-center w-[288px]">{serverError}</p>
        </div>
        <div className="text-center">
          <OutlineButton
            outlineColor="border-gray"
            textColor="text-gray"
            text="Cancel"
            widthClass="w-[120px]"
            heightClass="h-10"
            onClick={onClose}
          />
          <ColoredButton
            fillColor="bg-vs-green"
            text="Save"
            widthClass="w-[120px]"
            heightClass="h-10"
            submit={true}
          />
        </div>
      </form>
      {excludeResource && (
        <ConfirmationModal
          title="Are you sure?"
          text={getExcludedResourceConfirmationText(
            excludeResource.type,
            excludeResource.id,
            excludeResource.value,
            excludeResource.descendants_ids?.length || 0,
            'exclude'
          )}
          buttonText="Yes, I’m sure"
          overrideDefaultPosition="mt-4"
          onButtonClick={onConfirm}
          onClose={() => setExcludeResource(null)}
          isOpen={!!excludeResource}
        />
      )}
    </>
  );
}
