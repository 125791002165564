export const TopPanel = ({ rightSlot }) => {
  return (
    <div className="flex" id="status-container">
      <div className="flex flex-grow my-4 pr-1 h-[30px] items-center">
        <div className="flex w-1/2 pr-4 " id="actions-dropdown-placeholder" />
        <div
          className="w-1/2 flex justify-end"
          id="mapping-type-select-placeholder"
        >
          {rightSlot}
        </div>
      </div>
      <div
        className="flex justify-end items-center w-[250px] mr-[30px]"
        id="right-section-placeholder"
      />
    </div>
  );
};
