import { memo } from 'react';
import SelectAllHeader from '../../mapping/SelectAllHeader.jsx';
import { verifiedRenderer } from '../../../helpers/tableHelper.jsx';
import { getElementByAttribute } from '../../../helpers/commonHelper.jsx';
import { verified } from '../../../constants/constants.js';
import { COMPLEX_CATEGORY_LIST_TYPES } from '../../../constants/genericMappingConstants.js';

const selectAllColumnDef = {
  width: 25,
  maxWidth: 25,
  resizable: false,
  headerComponent: SelectAllHeader,
  suppressColumnsToolPanel: true,
  suppressNavigable: true,
  floatingFilter: false,
};

const verifiedColumnDef = {
  field: verified.field,
  headerName: verified.columnName,
  cellRenderer: memo(verifiedRenderer),
  width: 60,
  maxWidth: 75,
  cellDataType: 'boolean',
};

const mapReadOnlyColumns = (columns) => {
  return Object.entries(columns).map(([key, value]) => ({
    field: key,
    type: value,
    cellDataType: value,
  }));
};

const editableColumnsValueFormatter = (params, categoryList) => {
  // Fix for Filter component calling cell valueFormatter before calling it's own from filterParams
  if (!Array.isArray(params.value)) {
    return;
  }

  return params.value.map(
    (id) => getElementByAttribute(categoryList, 'category_id', id)?.value || id
  );
};

const mapEditableColumns = (columns, categoryListRecords) => {
  return Object.entries(columns).map(
    ([key, { category_list_id, category_list_type }]) => {
      const categoryList = categoryListRecords[category_list_id];

      return {
        field: key,
        type: category_list_type,
        cellDataType: category_list_type,
        ...(isComplexCategoryListType(category_list_type) && {
          valueFormatter: (params) =>
            editableColumnsValueFormatter(params, categoryList),
          filterParams: {
            values: categoryList,
          },
        }),
      };
    }
  );
};

export const createColumnDefs = (
  { read_only_columns, mappable_columns },
  categoryListRecords
) => {
  const readOnlyColumnDefs = mapReadOnlyColumns(read_only_columns);
  const mappableColumnDefs = mapEditableColumns(
    mappable_columns,
    categoryListRecords
  );

  return [
    selectAllColumnDef,
    ...readOnlyColumnDefs,
    ...mappableColumnDefs,
    verifiedColumnDef,
  ];
};

export const isComplexCategoryListType = (categoryListType) =>
  COMPLEX_CATEGORY_LIST_TYPES.includes(categoryListType);

export const getCategoryListIds = (mappingType) =>
  mappingType
    ? Object.values(mappingType.mappable_columns)
        .filter(({ category_list_type }) =>
          isComplexCategoryListType(category_list_type)
        )
        .map((item) => item.category_list_id)
    : [];
